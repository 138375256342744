<template>
    <DialogWrapper :show="show">
        <DialogCentered
            :show="show"
            :divider="false"
            class="newsletter-confirmation-modal"
            @close="closeModal()"
            @button-trailing-click="closeModal()"
        >
            <template #icon-trailing>
                <Icon :icon="Close" />
            </template>
            <NewsletterConfirmationInfo />
            <component
                :is="confirmButton.component"
                :variant="confirmButton.variant"
                class="button"
                @click.native="closeModal()"
            >
                {{ $t('I understand') }}
            </component>
        </DialogCentered>
    </DialogWrapper>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import DialogWrapper from '@molecules/DialogWrapper/DialogWrapper';
import NewsletterConfirmationInfo from '@molecules/NewsletterConfirmationInfo/NewsletterConfirmationInfo';

import { DialogCentered } from '@eobuwie-ui/components/DialogCentered/v1';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';
import {
    ButtonSmall,
    BUTTON_SMALL_VARIANTS,
} from '@eobuwie-ui/components/ButtonSmall/v1';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { Close } from '@eobuwie-ui/icons/v2/navigation';

const { mapActions: mapCustomerActions } = createNamespacedHelpers('customer');

export default {
    name: 'NewsletterConfirmationModal',

    components: {
        DialogWrapper,
        DialogCentered,
        NewsletterConfirmationInfo,
        ButtonLarge,
        ButtonSmall,
        Icon,
    },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        confirmButton() {
            const { PRIMARY } = BUTTON_LARGE_VARIANTS;
            const { PRIMARY: SMALL_PRIMARY } = BUTTON_SMALL_VARIANTS;

            if (this.isMobile) {
                return {
                    component: 'ButtonSmall',
                    variant: SMALL_PRIMARY,
                };
            }

            return {
                component: 'ButtonLarge',
                variant: PRIMARY,
            };
        },
    },

    beforeCreate() {
        this.Close = Close;
        this.BUTTON_LARGE_VARIANT = BUTTON_LARGE_VARIANTS.PRIMARY;
    },

    methods: {
        ...mapCustomerActions(['setShouldOpenModalNewsletterConfirmation']),

        closeModal() {
            this.setShouldOpenModalNewsletterConfirmation(false);
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/blurred-background';

.newsletter-confirmation-modal {
    @apply fixed top-0 bottom-0 left-0 right-0 z-4;

    &:deep() {
        .content {
            @apply flex-col items-center p-ui-4;
        }

        .dialog {
            @apply text-center z-5;
        }

        //@todo - remove in CLIPREF-1743
        .scrim.basic .scrim-overlay {
            @include blurred-background();
        }
    }

    .button {
        @apply mt-ui-4 mb-ui-2;
    }

    @screen lg {
        &:deep() {
            .content {
                @apply p-ui-6;
            }
        }

        .button {
            @apply mt-ui-6 mb-ui-0;
        }
    }
}
</style>
