<template>
    <div>
        <slot />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';

const { mapActions: mapLayoutActions } = createNamespacedHelpers('layout');

export default {
    name: 'DialogWrapper',

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    watch: {
        show: {
            async handler(show) {
                if (show) {
                    this.applyLockBodyScroll();

                    return;
                }

                this.removeLockBodyScroll();
            },
        },
    },

    mounted() {
        if (this.show) {
            this.applyLockBodyScroll();
        }
    },

    destroyed() {
        this.removeLockBodyScroll();
    },

    methods: {
        ...mapLayoutActions(['addHTMLClasses', 'removeHTMLClasses']),

        applyLockBodyScroll() {
            this.addHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        },

        removeLockBodyScroll() {
            this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        },
    },
};
</script>
